import axios from 'axios'
const http = axios.create({ baseURL: process.env.MIX_APP_URL })

export default class UrlService {
    static authSocialFacebookUrl () {
        return process.env.MIX_APP_URL + '/redirect/facebook'
    }

    static secondStepUrl () {
        return process.env.MIX_APP_URL + '/accounts/integrate'
    }

    static thirdStepUrl () {
        return process.env.MIX_APP_URL + '/accounts/integrate/setting'
    }

    static finishStepUrl () {
        return process.env.MIX_APP_URL + '/accounts/integrate/setting/complete'
    }

    static createCompanyUrl () {
        return process.env.MIX_APP_URL + '/dashboard/create'
    }

    static removeIntegration () {
        return http.post('/api/remove-integration')
    }

    static getProfile () {
        return http.get('/api/profile')
    }

    static saveProfile (data) {
        return http.post('/api/profile', data)
    }

    static hardSyncAccounts () {
        return http.get('/api/sync-facebook')
    }

    static getMyAdsAccounts () {
        return http.get('/api/accounts')
    }

    static getFilteringAccount (filter) {
        return http.get('/api/accounts' + filter)
    }

    static getOnboardingSelectedAccounts () {
        return http.get('/api/selected-accounts')
    }

    static changeLeadCost (data) {
        return http.post('/api/setting', data)
    }

    static changeLeadCostMass (data) {
        return http.post('/api/setting-set-mass-cpr', data)
    }

    static changeGlobalCTR (data) {
        return http.post('/api/setting/set-global', data)
    }

    static getGlobalCTR () {
        return http.get('/api/setting/get-global')
    }

    static syncAccounts (data) {
        return http.post('/api/save-selected', data)
    }

    static getSelectedAccounts (paginationUrl = null, size, filter = null) {

        if (!paginationUrl && filter) {
            return http.get('/api/dashboard' + filter + '&page[size]=' + size)
        }

        return http.get(paginationUrl ? paginationUrl : '/api/dashboard?page[size]=' + size)
    }

    static filtersSelectedAccounts (filter) {
            return http.get('/api/dashboard/date-range' + filter)
    }

    static getAccountNodes (nodeId, filter) {
            return http.get('/api/dashboard/get-child-account/' + nodeId + filter)
    }

    static getCampaignNodes (nodeFbId, filter) {
            return http.get('/api/dashboard/get-child-campaign/' + nodeFbId + filter)
    }

    static getAdsetNodes (nodeFbId, filter) {
            return http.get('/api/dashboard/get-child-adset/' + nodeFbId + filter)
    }

    static unselectAdAccount (accountsId) {
            return http.post('/api/dashboard/unselect', accountsId)
    }

    static getObjectiveOptions () {
            return http.get('/api/dashboard/campaign/objective')
    }

    static getStatusesOptions () {
            return http.get('/api/dashboard/campaign/statuses')
    }

    static getPromotedObjectOptions () {
            return http.get('/api/dashboard/campaign/promoted-objects')
    }

    static getBillingEventsOptions () {
            return http.get('/api/dashboard/campaign/billing-events')
    }

    static getSpecCategsOptions () {
            return http.get('/api/dashboard/campaign/special-categories')
    }

    static getCampaignsList (adaccountId) {
            return http.get('/api/dashboard/campaign/' + adaccountId)
    }

    static createCampaign (data) {
            return http.post('/api/dashboard/campaign/store', data)
    }

    static createAdset (data) {
            return http.post('/api/dashboard/campaign/store-adset', data)
    }

    static toggleNodeStatus(data){
        return http.post('/api/toggle-node', data)
    }

    static setBudget(data){
        return http.post('/api/set-daily-budget-node', data)
    }


}
