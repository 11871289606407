import UrlService from './UrlService'

export default class StatsService {

    static async getChildNodes (accountsList, account = null, campaign = null, adset = null, dateRange, statuses){
        let filter = '?time_range[since]=' + dateRange[0] + '&time_range[until]=' + dateRange[1]
        statuses.campaign ? filter += '&filter[campaign_status]=' + statuses.campaign : null
        statuses.adset ? filter += '&filter[adset_status]=' + statuses.adset : null
        statuses.ads ? filter += '&filter[ads_status]=' + statuses.ads : null
        statuses.sortCPRvalue ? filter += '&filter[cpr_value]=' + statuses.sortCPR + statuses.sortCPRvalue : null

        if (account && !account.campaigns) {
            let response = await UrlService.getAccountNodes(account.id, filter)
            accountsList = accountsList.map(function(acc){
                if (acc.id == account.id) {
                    acc.campaigns = response.data.data
                }
                return acc;
            } )

        }
        if (campaign && !campaign.adsets) {
            let response = await UrlService.getCampaignNodes(campaign.facebook_id, filter)
            accountsList = accountsList.map(function(acc){
                if (acc.campaigns) {
                    acc.campaigns.map(function(camp){
                        if (camp.id == campaign.id) {
                            camp.adsets = response.data.data
                        }
                    })

                }
                return acc;
            } )
        }
        if (adset && !adset.adss) {
            let response = await UrlService.getAdsetNodes(adset.facebook_id, filter)
            accountsList = accountsList.map(function(acc){
                if (acc.campaigns) {
                    acc.campaigns.map(function(camp){
                        if (camp.adsets) {
                            camp.adsets.map(function(adsGroup){
                                if (adset.id == adsGroup.id) {
                                    adsGroup.adss = response.data.data
                                }

                            })
                        }

                    })

                }
                return acc;
            } )

        }

        return accountsList;
    }

}
