<template>
    <div class="onboarding">
        <div class="onboarding__counter">
        </div>
        <div class="onboarding__content">
            <div class="onboarding__info">
            <h3 class="onboarding__title"></h3>
            <p class="onboarding__subtitle">Facebook integration</p>
            </div>
            <div class="onboarding__button-wrap">
            <a v-if="!integrated" :href="facebookAuthUrl" type="button" class="button button--large">
                <div class="icon-wrap">
                    <img src="/img/svg/facebook.svg" alt="facebook" />
                </div>
                Integrate
            </a>
            <a v-else @click="deleteIntegration()" type="button" class="button button--large">
                <div class="icon-wrap">
                    <img src="/img/svg/facebook.svg" alt="facebook" />
                </div>
                Remove Integration
            </a>
            </div>
        </div>
    </div>
</template>

<script>
import UrlService from '../services/UrlService'
    export default {
        data() {
            return {
                facebookAuthUrl: UrlService.authSocialFacebookUrl(),
                integrated: false
            }
        },
        mounted() {
            this.getIntegration()
        },
        methods:{
            async getIntegration(){
                let integrationResponse = await UrlService.getProfile().catch(err => {
                        return err
                    })

                if (integrationResponse.status === 200) {
                    this.integrated = integrationResponse.data.data.facebookAccounts
                } else {
                    this.$store.commit('setDismissCountDown', 5)
                    this.$store.commit('setAlertText', integrationResponse.response.data.message)
                    this.$store.commit('setVariantMessage', 'danger')
                }
            },

            async deleteIntegration(){
                let response = await UrlService.removeIntegration().catch(err => {
                        return err
                    })

                if (response.status === 200) {
                    this.integrated = response.data.data.facebookAccounts
                    this.$store.commit('setDismissCountDown', 5)
                    this.$store.commit('setAlertText', 'Your facebook account has been de-integrated')
                    this.$store.commit('setVariantMessage', 'success')
                    window.location.reload()
                } else {
                    this.$store.commit('setDismissCountDown', 5)
                    this.$store.commit('setAlertText', response.response.data.message)
                    this.$store.commit('setVariantMessage', 'danger')
                }

            }
        }

    }
</script>
