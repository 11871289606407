<template>

    <div class="h-100 dashboard__wrap_table">
        <vue-confirm-dialog></vue-confirm-dialog>
            <div class="table-header-filters position-sticky">
                <div class="header-sticky">
                    <div class="dashboard__top-content">
                        <div class="dashboard__filters-wrap">
                            <div class="input-wrap input-wrap--search">
                                <input v-model="searchValue" class="text-input text-input--search" type="text" placeholder="Search by accounts" />
                            </div>
                            <div class="input-wrap datapicker-class">
                                <date-picker
                                    value-type="YYYY-MM-DD"
                                    type="date"
                                    range
                                    v-model="dateRange"
                                    format="ddd, MMM DD, YYYY"
                                    :shortcuts="shortcuts"
                                >
                                </date-picker>
                            </div>
                            <button @click="showFilterModal = !showFilterModal" type="button" class="button button--transparent button--middle">
                                <div class="icon-wrap icon-wrap--transparent">
                                    <img src="/img/svg/filter.svg" alt="filter" />
                                </div>
                                Filters
                            </button>
                        </div>
                        <!-- <button @click="createCompany" type="button" class="button button--large">
                            <div class="icon-wrap icon-wrap--transparent button--large">
                                <img src="/img/svg/plus.svg" alt="create company" />
                            </div>
                            Create company
                        </button> -->
                        <button @click="goToSettings" type="button" class="button button--large" style="margin-left: 35px;">
                            Manage Ad Accounts
                        </button>
                    </div>
                    <div class="position-relative filter-bar-place d-flex justify-content-center">
                        <div v-show="filterBar" class="dashboard__filter-bar">
                            <div class="dashboard__filter-bar-inner">
                                <button @click="selectAllCheckBoxes" class="dashboard__filter-bar-button">Choose All</button>
                                <button @click="selectCheckBoxesClear" class="dashboard__filter-bar-button">
                                    <img src="img/svg/clear.svg" alt="clear" />
                                    <p>Clear</p>
                                </button>
                            </div>
                            <div class="dashboard__filter-bar-inner">
                                <button class="dashboard__filter-bar-button">
                                    <img src="img/svg/survey.svg" alt="clear" />
                                    <p>Survey</p>
                                </button>
                                <button @click="showRowsAdAccounts" class="dashboard__filter-bar-button">
                                    <img src="img/svg/eye.svg" alt="clear" />
                                    <p>Show</p>
                                </button>
                                <button @click="hideRowsAdAccounts" class="dashboard__filter-bar-button">
                                    <img src="img/svg/hide.svg" alt="clear" />
                                    <p>Hide</p>
                                </button>
                                <button class="dashboard__filter-bar-button">
                                    <img src="img/svg/pencil.svg" alt="clear" />
                                    <p>Correct</p>
                                </button>
                                <button @click="unselectAdAccounts" class="dashboard__filter-bar-button">
                                    <img src="img/svg/trash.svg" alt="clear" />
                                    <p>Delete</p>
                                </button>
                            </div>
                            <p class="dashboard__filter-bar-choosen">Choosen {{ selectedRowsAccounts.length }} of  {{ accounts.length }}</p>
                        </div>
                    </div>
            </div>
            <div class="dashboard__table">
                <div class="dashboard__table-head">
                    <!-- <button type="button" class="dashboard__head-item"></button> -->
                    <button @click="orderByAccounts('name', sort = !sort)" type="button" class="dashboard__head-item">
                        <h6>Name</h6>
                        <img src="/img/svg/arrow-up-sort.svg" alt="sorting" />
                    </button>
                    <button @click="orderByAccounts('objective', sort = !sort)" type="button" class="dashboard__head-item">
                        <h6>Objective</h6>
                        <img src="/img/svg/arrow-up-sort.svg" alt="sorting" />
                    </button>
                    <button @click="orderByAccounts('spend', sort = !sort)" type="button" class="dashboard__head-item">
                        <h6>Spent</h6>
                        <img src="/img/svg/arrow-up-sort.svg" alt="sorting" />
                    </button>
                    <button @click="orderByAccounts('amount', sort = !sort)" type="button" class="dashboard__head-item">
                        <h6>Result</h6>
                        <img src="/img/svg/arrow-up-sort.svg" alt="sorting" />
                    </button>
                    <button @click="orderByAccounts('cpr', sort = !sort)" type="button" class="dashboard__head-item justify-content-center">

                            <popper
                            trigger="hover"
                            :options="{
                            placement: 'top',
                            modifiers: { offset: { offset: '-0,10px' } }
                            }">
                                <div class="popper">
                                    Cost Per Result
                                </div>
                                <h6 slot="reference">CPR</h6>
                            </popper>
                        <img src="/img/svg/arrow-up-sort.svg" alt="sorting" />
                    </button>
                    <button @click="orderByAccounts('impressions', sort = !sort)" type="button" class="dashboard__head-item">
                        <h6>Impressions</h6>
                        <img src="/img/svg/arrow-up-sort.svg" alt="sorting" />
                    </button>
                    <button @click="orderByAccounts('frequency', sort = !sort)" type="button" class="dashboard__head-item">
                        <h6>Frequency</h6>
                        <img src="/img/svg/arrow-up-sort.svg" alt="sorting" />
                    </button>
                    <button @click="orderByAccounts('reach', sort = !sort)" type="button" class="dashboard__head-item">
                        <h6>Reach</h6>
                        <img src="/img/svg/arrow-up-sort.svg" alt="sorting" />
                    </button>
                    <button @click="orderByAccounts('cpm', sort = !sort)" type="button" class="dashboard__head-item">
                        <popper
                            trigger="hover"
                            :options="{
                            placement: 'top',
                            modifiers: { offset: { offset: '-0,10px' } }
                            }">
                                <div class="popper">
                                    Cost Per 1000 Impressions
                                </div>
                                <h6 slot="reference">CPM</h6>
                            </popper>
                        <img src="/img/svg/arrow-up-sort.svg" alt="sorting" />
                    </button>
                    <button @click="orderByAccounts('ulc', sort = !sort)" type="button" class="dashboard__head-item">
                        <popper
                            trigger="hover"
                            :options="{
                            placement: 'top',
                            modifiers: { offset: { offset: '-0,10px' } }
                            }">
                                <div class="popper">
                                    Unique Link Clicks
                                </div>
                                <h6 slot="reference">ULC</h6>
                            </popper>
                        <img src="/img/svg/arrow-up-sort.svg" alt="sorting" />
                    </button>
                    <button @click="orderByAccounts('cpulc', sort = !sort)" type="button" class="dashboard__head-item">
                        <popper
                            trigger="hover"
                            :options="{
                            placement: 'top',
                            modifiers: { offset: { offset: '-0,10px' } }
                            }">
                                <div class="popper">
                                    Cost Per Unique Link Click
                                </div>
                                <h6 slot="reference">CPULC</h6>
                            </popper>
                        <img src="/img/svg/arrow-up-sort.svg" alt="sorting" />
                    </button>
                    <button @click="orderByAccounts('clicks', sort = !sort)" type="button" class="dashboard__head-item">
                        <h6>Clicks</h6>
                        <img src="/img/svg/arrow-up-sort.svg" alt="sorting" />
                    </button>
                    <button @click="orderByAccounts('cpc', sort = !sort)" type="button" class="dashboard__head-item">
                        <popper
                            trigger="hover"
                            :options="{
                            placement: 'top',
                            modifiers: { offset: { offset: '-0,10px' } }
                            }">
                                <div class="popper">
                                    Cost Per Click (all)
                                </div>
                                <h6 slot="reference">CPC</h6>
                            </popper>
                        <img src="/img/svg/arrow-up-sort.svg" alt="sorting" />
                    </button>
                    <button @click="orderByAccounts('link_clicks', sort = !sort)" type="button" class="dashboard__head-item">
                        <h6>Link Clicks</h6>
                        <img src="/img/svg/arrow-up-sort.svg" alt="sorting" />
                    </button>
                    <button @click="orderByAccounts('cost_per_inline_link_click', sort = !sort)" type="button" class="dashboard__head-item">
                        <h6>Cost Per Link Click</h6>
                        <img src="/img/svg/arrow-up-sort.svg" alt="sorting" />
                    </button>
                    <button @click="orderByAccounts('ccvr', sort = !sort)" type="button" class="dashboard__head-item">
                        <popper
                            trigger="hover"
                            :options="{
                            placement: 'top',
                            modifiers: { offset: { offset: '-0,10px' } }
                            }">
                                <div class="popper">
                                    Copy Conversion Rate
                                </div>
                                <h6 slot="reference">CCVR</h6>
                            </popper>
                        <img src="/img/svg/arrow-up-sort.svg" alt="sorting" />
                    </button>
                    <button @click="orderByAccounts('cvr', sort = !sort)" type="button" class="dashboard__head-item">
                        <popper
                            trigger="hover"
                            :options="{
                            placement: 'top',
                            modifiers: { offset: { offset: '-0,10px' } }
                            }">
                                <div class="popper">
                                    Conversion Rate
                                </div>
                                <h6 slot="reference">CVR</h6>
                            </popper>
                        <img src="/img/svg/arrow-up-sort.svg" alt="sorting" />
                    </button>
                    <button type="button" class="dashboard__head-item">
                    </button>
                </div>
                <loader :isLoading="isLoading"></loader>
                <ul v-if="accounts && !isLoading" class="dashboard__table-body" :class="{'cursor_wait': isTogglingNode, 'cursor_pointer': !isTogglingNode}">
                    <li v-for="account in accounts" :key="account.id" class="dashboard__body-item-wrap shadow-lg" :class="['account_' + account.class]">
                        <div @click="getNodes(account),toggleVisibleRow(account, 'acc')" :class="[account.class, {disabled:('acc' + account.id) in loading}, {'opacity-25':('acc' + account.id) in hideRows && hideRows[('acc' + account.id)]}]" class="dashboard__body-item position-relative">
                            <loader v-if="('acc' + account.id) in loading" class="dashboard-row" :isLoading="true"></loader>

                            <!-- <div class="dashboard__checkbox-wrap">
                                <input type="checkbox" class="dashboard__checkbox" name="accs" v-model="selectedRowsAccounts" :value="account.id" :id="'acc' + account.id" />
                                <label :for="'acc' + account.id" class="dashboard__checkbox-label"></label>
                            </div> -->
                            <p class="dashboard-table-name">{{ account.name }}</p>
                                <dashboard-row-node :node="account"></dashboard-row-node>
                            <button type="button" class="dashboard__dropdown">
                                <img :class="{'open-close-row': (('acc' + account.id) in showingRow && showingRow[('acc' + account.id)])}" src="/img/svg/dropdown.svg" alt="dropdown" />
                            </button>
                        </div>
                        <ul v-if="account.campaigns && (('acc' + account.id) in showingRow && showingRow[('acc' + account.id)])">
                            <li v-for="campaign in account.campaigns" :key="campaign.id" class="position-relative">
                                <popper v-if="campaign.daily_budget"
                                    trigger="click"
                                    :options="{
                                        placement: 'top',
                                        modifiers: { offset: { offset: '0,10px' } }
                                    }">
                                    <div class="popper">
                                        <div class="input-wrap position-relative">
                                            <span @click.prevent="campaign = setBudget(campaign, 'campaign')" class="ok-budget position-absolute"><i class="bi bi-check-circle-fill"></i></span>
                                            <span @click.prevent="closePopper('poppercampaign' + campaign.id)" class="cancel-budget position-absolute"><i class="bi bi-x-circle-fill"></i></span>
                                            <input :id="'campaign' + campaign.id" class="form-control text-input input-budget" :value="campaign.daily_budget / 100" />
                                        </div>
                                    </div>

                                    <div :id="'poppercampaign' + campaign.id" class="btn-budget opacity-75" slot="reference">
                                        <span>Daily budget:</span>
                                        <i class="bi bi-pencil-square edit-budget-icon"></i>
                                        <span slot="reference">{{ new Intl.NumberFormat('en-US', { style: 'currency', currency: campaign.currency, maximumFractionDigits: 0 }).format(campaign.daily_budget / 100) }}</span>
                                    </div>
                                </popper>
                                <div @click="getNodes(null, campaign),toggleVisibleRow(campaign, 'camp')" :class="[campaign.class, {disabled:('camp' + campaign.id) in loading}]" class="dashboard__body-item dashboard__body-subitem">
                                    <loader v-if="('camp' + campaign.id) in loading" class="dashboard-row" :isLoading="true"></loader>
                                    <div class="dashboard__checkbox-wrap campaign-checkbox">
                                        <label class="switch">
                                            <input @change.prevent="campaign = toggleStatus(campaign, 'campaign', $event)" :checked="campaign.status == 'ACTIVE'" type="checkbox">
                                            <span class="slider round"></span>
                                        </label>
                                    </div>
                                    <p class="campaign-class">
                                        <popper
                                            trigger="hover"
                                            :options="{
                                            placement: 'top',
                                            modifiers: { offset: { offset: '0,05px' } }
                                            }">
                                            <div class="popper">
                                                {{ campaign.name }}
                                            </div>
                                            <span slot="reference">
                                                {{ campaign.name }}
                                            </span>
                                        </popper>
                                    </p>
                                        <dashboard-row-node :node="campaign"></dashboard-row-node>
                                    <button type="button" class="dashboard__dropdown">
                                        <img :class="{'open-close-row': (('camp' + campaign.id) in showingRow && showingRow[('camp' + campaign.id)])}" src="/img/svg/dropdown.svg" alt="dropdown" />
                                    </button>
                                </div>
                                <ul v-if="campaign.adsets && (('camp' + campaign.id) in showingRow && showingRow[('camp' + campaign.id)])">
                                    <li v-for="adset in campaign.adsets" :key="adset.id" class="position-relative">
                                        <popper v-if="adset.daily_budget"
                                            trigger="click"
                                            :options="{
                                                placement: 'top',
                                                modifiers: { offset: { offset: '0,10px' } }
                                            }">
                                            <div class="popper">
                                                <div class="input-wrap position-relative">
                                                    <span @click.prevent="adset = setBudget(adset, 'adset')" class="ok-budget position-absolute"><i class="bi bi-check-circle-fill"></i></span>
                                                    <span @click.prevent="closePopper('popperadset' + adset.id)" class="cancel-budget position-absolute"><i class="bi bi-x-circle-fill"></i></span>
                                                    <input :id="'adset' + adset.id" class="form-control text-input input-budget" :value="adset.daily_budget / 100" />
                                                </div>
                                            </div>

                                            <div :id="'popperadset' + adset.id" class="btn-budget opacity-75" slot="reference">
                                                <span>Daily budget:</span>
                                                <i class="bi bi-pencil-square edit-budget-icon"></i>
                                                <span slot="reference">{{ new Intl.NumberFormat('en-US', { style: 'currency', currency: adset.currency, maximumFractionDigits: 0 }).format(adset.daily_budget / 100) }}</span>
                                            </div>
                                        </popper>
                                        <div href="#" @click="getNodes(null, null, adset),toggleVisibleRow(adset, 'adset')" :class="[adset.class, {disabled:('adset' + adset.id) in loading}]" class="dashboard__body-item dashboard__body-subitem position-relative">
                                            <loader v-if="('adset' + adset.id) in loading" class="dashboard-row" :isLoading="true"></loader>
                                            <div class="dashboard__checkbox-wrap adset-checkbox">
                                                <label class="switch">
                                                    <input @change="adset = toggleStatus(adset, 'adset', $event)" :checked="adset.status == 'ACTIVE'" type="checkbox">
                                                    <span class="slider round"></span>
                                                </label>
                                            </div>
                                            <p class="adset-class">
                                                <popper
                                                    trigger="hover"
                                                    :options="{
                                                    placement: 'top',
                                                    modifiers: { offset: { offset: '0,05px' } }
                                                    }">
                                                    <div class="popper">
                                                        {{ adset.name }}
                                                    </div>
                                                    <span slot="reference">
                                                        {{ adset.name }}
                                                    </span>
                                                </popper>
                                            </p>
                                                <dashboard-row-node :node="adset"></dashboard-row-node>
                                            <button type="button" class="dashboard__dropdown">
                                                <img :class="{'open-close-row': (('adset' + adset.id) in showingRow && showingRow[('adset' + adset.id)])}" src="/img/svg/dropdown.svg" alt="dropdown" />
                                            </button>
                                        </div>
                                        <ul v-if="adset.adss && (('adset' + adset.id) in showingRow && showingRow[('adset' + adset.id)])">
                                            <li v-for="ads in adset.adss" :key="ads.id">
                                                <div :class="ads.class" class="dashboard__body-item dashboard__body-subitem">
                                                    <div class="dashboard__checkbox-wrap ads-checkbox">
                                                        <label class="switch">
                                                            <input @change="ads = toggleStatus(ads, 'ads', $event)" :checked="ads.status == 'ACTIVE'" type="checkbox">
                                                            <span class="slider round"></span>
                                                        </label>
                                                        <!-- <input type="checkbox" class="dashboard__checkbox" v-model="selectedRowsAds" :value="ads.id" :id="ads.id" />
                                                        <label :for="ads.id" class="dashboard__checkbox-label"></label> -->
                                                    </div>
                                                    <p class="ads-class">
                                                        <popper
                                                            trigger="hover"
                                                            :options="{
                                                            placement: 'top',
                                                            modifiers: { offset: { offset: '0,05px' } }
                                                            }">
                                                            <div class="popper">
                                                                {{ ads.name }}
                                                            </div>
                                                            <span slot="reference">
                                                                {{ ads.name }}
                                                            </span>
                                                        </popper>
                                                    </p>
                                                        <dashboard-row-node :node="ads"></dashboard-row-node>
                                                    <div></div>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>

                            </li>

                        </ul>
                    </li>
                </ul>
                <div v-if="!accounts.length && !isLoading" class="text-center p-5 font-weight-bold" style="font-size: 20px;">
                    <h1>No Ad Accounts Found.</h1>
                    <p class="mt-1">Please check the name of the ad account you are searching</p>
                    <p>for and make sure the ad account has been integrated.</p>
                </div>

                <div style="margin-top: 50px;"></div>

                <div v-if="pagination && !isLoading && (pagination.last_page > 1)" class="dashboard__pagination-wrap mt-1">
                <p class="dashboard__pagination-info">{{ pagination.from }}-{{ pagination.to }} of {{ pagination.total }}</p>
                <div class="dashboard__pagination">
                    <button
                    @click="getSelectedAccountsData(pagination.links[0].url ? pagination.links[0].url.replace('http', 'https') : null)"
                    type="button"
                    class="dashboard__pagination-button dashboard__pagination-arrow dashboard__pagination-arrow--left"
                    :disabled="!pagination.links[0].url">
                    </button>

                    <button
                        @click="getSelectedAccountsData(page.url ? page.url.replace('http', 'https') : null)"
                        v-for="page in pagination.links"
                        type="button"
                        :class="[page.active ? 'dashboard__pagination-button--active' : !parseInt(page.label) ? 'd-none' : '']"
                        class="dashboard__pagination-button">
                        {{ page.label }}
                    </button>

                    <button
                    @click="getSelectedAccountsData(pagination.links.slice(-1).pop().url ? pagination.links.slice(-1).pop().url.replace('http', 'https') : null)"
                    type="button"
                    class="dashboard__pagination-button dashboard__pagination-arrow dashboard__pagination-arrow--right"
                    :disabled="!pagination.links.slice(-1).pop().url">
                    </button>
                </div>
                <div class="dashboard__per-page-wrap">
                    <p>Per page:</p>
                    <select v-model="selected" class="dashboard__per-page-select">
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                    </select>
                </div>
            </div>

            </div>
            </div>

            <div v-show="showFilterModal" class="dashboard__filters-dropdown">
                <h3 class="dashboard__dropdown-title">Filters</h3>
                <label class="p-1" for="tlc">Traffic Light System Filter</label>
                <div id="tlc" class="dashboard__select-wrap dashboard__filter-select-wrap">
                    <select v-model="tls" class="dashboard__select dashboard__filter-select">
                        <option value=""></option>
                        <option value="green-class">Green</option>
                        <option value="orange-warning">Orange</option>
                        <option value="red-danger">Red</option>
                    </select>
                </div>
                <label class="p-1" for="cpr">Filter by Cost Per Result</label>
                <div id="cpr" class="dashboard__select-wrap dashboard__filter-select-wrap">
                    <select v-model="sortCPR" class="dashboard__select dashboard__filter-select">
                        <option value=""></option>
                        <option value="<">Less than or equal</option>
                        <option value=">">Greater than or equal</option>
                    </select>
                </div>
                <div v-if="sortCPR">
                    <input type="text" v-model="sortCPRvalue" :placeholder="sortCPR === '<' ? 'Less than or equal' : 'Greater than or equal'" class="cpr-input text-input text-input--search w-100">
                </div>
                <h4 class="dashboard__dropdown-subtitle mt-3">
                    Campaigns
                </h4>
                <div class="dashboard__radio-wrap">
                    <input v-model="campaniesStatus" type="radio" name="companies" value="" id="companies-all" class="dashboard__radio" />
                    <label for="companies-all" class="dashboard__radio-label">All</label>
                    <input v-model="campaniesStatus" type="radio" name="companies" value="ACTIVE" id="companies-active" class="dashboard__radio" />
                    <label for="companies-active" class="dashboard__radio-label">Active</label>
                    <input v-model="campaniesStatus" type="radio" name="companies" value="PAUSED" id="companies-inactive" class="dashboard__radio" />
                    <label for="companies-inactive" class="dashboard__radio-label">Inactive</label>
                </div>
                <h4 class="dashboard__dropdown-subtitle">
                    Adsets
                </h4>
                <div class="dashboard__radio-wrap">
                    <input v-model="adsetStatus" type="radio" name="adset" value="" id="adset-all" class="dashboard__radio" />
                    <label for="adset-all" class="dashboard__radio-label">All</label>
                    <input v-model="adsetStatus" type="radio" name="adset" value="ACTIVE" id="adset-active" class="dashboard__radio" />
                    <label for="adset-active" class="dashboard__radio-label">Active</label>
                    <input v-model="adsetStatus" type="radio" name="adset" value="PAUSED" id="adset-inactive" class="dashboard__radio" />
                    <label for="adset-inactive" class="dashboard__radio-label">Inactive</label>
                </div>
                <h4 class="dashboard__dropdown-subtitle">
                    Ads
                </h4>
                <div class="dashboard__radio-wrap">
                    <input v-model="adsStatus" type="radio" name="ads" value="" id="ads-all" class="dashboard__radio" />
                    <label for="ads-all" class="dashboard__radio-label">All</label>
                    <input v-model="adsStatus" type="radio" name="ads" value="ACTIVE" id="ads-active" class="dashboard__radio" />
                    <label for="ads-active" class="dashboard__radio-label">Active</label>
                    <input v-model="adsStatus" type="radio" name="ads" value="PAUSED" id="ads-inactive" class="dashboard__radio" />
                    <label for="ads-inactive" class="dashboard__radio-label">Inactive</label>
                </div>
                <div class="dashboard__dropdown-buttons-wrap">
                    <button @click="clearStatusFilters" type="button" class="button button--transparent button--middle">
                        Clear
                    </button>
                    <button @click="applyStatusFilters" type="button" class="button button--middle">
                        Apply
                    </button>
                </div>
            </div>
        </div>
</template>

<script>

import UrlService from '../services/UrlService'
import Loader from '../Loader.vue'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import debounce from 'lodash.debounce'
import StatsService from '../services/StatsService';
import moment from 'moment'
import DashboardRowNode from './DashboardRowNode.vue'
import Popper from 'vue-popperjs';
import 'vue-popperjs/dist/vue-popper.css';

    export default {
        components:{
            Loader, DatePicker, DashboardRowNode, Popper
        },
        data() {
            return {
                accounts: [],
                isLoading: true,
                selectedRowsAccounts: [],
                selectedRowsCampaigns: [],
                selectedRowsAdsets: [],
                selectedRowsAds: [],
                pagination: null,
                selected: 10,
                searchValue: '',
                dateRange: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
                showFilterModal: false,
                campaniesStatus: '',
                adsetStatus: '',
                adsStatus: '',
                loading: [],
                sort: true,
                showingRow: [],
                tls: '',
                sortTls: '',
                sortCPR: '',
                sortCPRvalue: null,
                filterBar: false,
                hideRows: [],
                isTogglingNode: false,
                shortcuts:[
                    { text: 'Today', onClick: () => this.setDaysRange(0) },
                    { text: 'Yesterday', onClick: () => this.setDaysRange(1) },
                    { text: 'Last 3 Days', onClick: () => this.setDaysRange(2) },
                    { text: 'Last 7 Days', onClick: () => this.setDaysRange(6) },
                    { text: 'Last 14 Days', onClick: () => this.setDaysRange(13) },
                    { text: 'This week', onClick: () => this.setPeriodDateRange('week') },
                    { text: 'Last week', onClick: () => this.setPeriodDateRange('week', 'weeks') },
                    { text: 'This month', onClick: () => this.setPeriodDateRange('month') },
                    { text: 'Last month', onClick: () => this.setPeriodDateRange('month', 'months') },
                    { text: 'This year', onClick: () => this.setPeriodDateRange('year') },
                ],
                isOpenBudget: false
            }
        },
        async mounted() {
            await this.dateRangeFilter()
        },
        methods:{
            async getSelectedAccountsData(url = null, size, filter = null){
                this.isLoading = true
                let defaultFilter = '?time_range[since]=' + this.dateRange[0] + '&time_range[until]=' + this.dateRange[1]

                filter ? defaultFilter += filter : null

                this.campaniesStatus ? defaultFilter +=  '&filter[campaign_status]=' + this.campaniesStatus : null
                this.adsetStatus ? defaultFilter +=  '&filter[adset_status]=' + this.adsetStatus : null
                this.adsStatus ? defaultFilter +=  '&filter[ads_status]=' + this.adsStatus : null
                this.tls ? defaultFilter +=  '&filter[adaccount_bg]=' + this.tls : null
                this.sortTls ? defaultFilter +=  '&sort=bg' + this.sortTls : null
                this.sortCPR != '' ? defaultFilter += '&filter[cpr_value]=' + this.sortCPR + this.sortCPRvalue : null

                const response = await UrlService.getSelectedAccounts(url, size, defaultFilter).catch(err => {
                    return err
                })

                if (response.status === 200) {
                    this.accounts = response.data.data;
                    this.pagination = response.data.meta;
                } else {
                    this.$store.commit('setDismissCountDown', 5)
                    this.$store.commit('setAlertText', response.response.data.message)
                    this.$store.commit('setVariantMessage', 'danger')
                }

                this.showingRow = []
                this.loading = []

                this.isLoading = false
            },
            async search(){
                if(this.searchValue.length > 1){
                    this.getSelectedAccountsData(null, this.selected, '&filter[name]=' + this.searchValue );
                }else {
                    this.getSelectedAccountsData(null, this.selected)
                }

            },
            dateRangeFilter(){
                this.accounts = [];
                return this.getSelectedAccountsData(null, this.selected)
            },
            setDaysRange(day){
                return day === 1
                        ? [moment().subtract(day,'d').toDate(), moment().subtract(day,'d').toDate()]
                        : [moment().subtract(day,'d').toDate(), moment().toDate()]
            },
            setPeriodDateRange(period, subtract = null){
                    return subtract
                        ? [moment().subtract(1, subtract).startOf(period).toDate(), moment().subtract(1, subtract).endOf(period).toDate()]
                        : [moment().startOf(period).toDate(), moment().endOf(period).toDate()];
            },
            async getNodes(account = null, campaign = null, adset = null){
                this.isTogglingNode = true
                let keyName = account ? ["acc" + account.id]
                            : campaign ? ["camp" + campaign.id]
                            : adset ? ["adset" + adset.id] : null

                this.$set(this.loading, keyName, true)

                let statuses = {
                    campaign: this.campaniesStatus,
                    ads: this.adsStatus,
                    adset: this.adsetStatus,
                    sortCPR: this.sortCPR,
                    sortCPRvalue: this.sortCPRvalue
                }

                try{
                    this.accounts = await StatsService.getChildNodes(this.accounts, account, campaign, adset, this.dateRange, statuses)
                } catch (error) {
                    this.$store.commit('setDismissCountDown', 5)
                    this.$store.commit('setAlertText', error.response.data.message)
                    this.$store.commit('setVariantMessage', 'danger')
                }

                this.loading = []
                this.isTogglingNode = false
            },
            async toggleVisibleRow(rowNode, keyPrefix){
                let keyName = keyPrefix + rowNode.id;
                let toggle =  true;
                (keyName in this.showingRow) ? toggle = !this.showingRow[keyName] : toggle
                this.$set(this.showingRow, keyName, toggle)
            },
            async applyStatusFilters(){
                this.showFilterModal = false
                this.getSelectedAccountsData(null, this.selected)
            },
            async clearStatusFilters(){
                this.showFilterModal = false
                this.campaniesStatus = ''
                this.adsetStatus = ''
                this.adsStatus = ''
                this.tls = ''
                this.sortTls = ''
                this.sortCPR = ''
                this.sortCPRvalue = null
                this.getSelectedAccountsData(null, this.selected)
            },
            orderByAccounts(column, sortable = true) {
                this.accounts = _.orderBy(this.accounts, column, sortable ? 'asc' : 'desc')
            },
            selectAllCheckBoxes(){
                this.selectCheckBoxesClear()
                this.accounts.map((account)=>{
                    this.selectedRowsAccounts.push(account.id)
                })
            },
            selectCheckBoxesClear(){
                this.selectedRowsAccounts = []
            },
            async unselectAdAccounts(){
                let response = await UrlService.unselectAdAccount({ids:this.selectedRowsAccounts})
                this.selectedRowsAccounts = []
                this.getSelectedAccountsData(null, this.selected);
            },
            hideRowsAdAccounts(){
                this.selectedRowsAccounts.map((rowAdAccountId)=>{
                    let keyName = ["acc" + rowAdAccountId]
                    this.$set(this.hideRows, keyName, true)
                })
            },
            showRowsAdAccounts(){
                this.selectedRowsAccounts.map((rowAdAccountId)=>{
                    let keyName = ["acc" + rowAdAccountId]
                    this.$set(this.hideRows, keyName, false)
                })
            },
            createCompany(){
                window.location.href = UrlService.createCompanyUrl()
            },
            goToSettings(){
                window.location.href = '/accounts/integrate'
            },
            async toggleStatus(object, instance, event){
                this.isTogglingNode = true
                object.status = object.status == 'ACTIVE' ? 'PAUSED' : 'ACTIVE'
                let data = {
                    facebook_id: object.facebook_id,
                    status: object.status,
                    node_instance: instance,
                }

                let response = await UrlService.toggleNodeStatus(data).catch(err => {
                    return err
                })

                if (response.status !== 200) {
                    object.status = object.status == 'ACTIVE' ? 'PAUSED' : 'ACTIVE'
                    this.$store.commit('setDismissCountDown', 5)
                    this.$store.commit('setAlertText', response.response.data.message)
                    this.$store.commit('setVariantMessage', 'danger')
                }

                event.target.checked = object.status == 'ACTIVE' ? true : false
                await new Promise(resolve => setTimeout(resolve, 5000));
                this.isTogglingNode = false
                return object;
            },
            async setBudget(object, instance){
                let budget = document.getElementById(instance + object.id).value
                this.$confirm(
                    {
                    message: 'Would you like to set this daily budget for this '+ instance +' ' + budget + ' ' + object.currency + '?',
                    button: {
                        no: 'No',
                        yes: 'Yes'
                    },
                    /**
                     * Callback Function
                     * @param {Boolean} confirm
                     */
                    callback: async confirm => {
                        if (confirm) {
                            this.isTogglingNode = true
                            let data = {
                                facebook_id: object.facebook_id,
                                daily_budget: budget,
                                node_instance: instance,
                            }
                            let response = await UrlService.setBudget(data).catch(err => {
                                return err
                            })

                            if (response.status !== 200) {
                                this.$store.commit('setDismissCountDown', 5)
                                this.$store.commit('setAlertText', response.response.data.error_user_msg ?? response.response.data.error_user_title ?? response.response.data.message)
                                this.$store.commit('setVariantMessage', 'danger')
                            } else {
                                object.daily_budget = response.data.data.daily_budget
                                this.$store.commit('setDismissCountDown', 5)
                                this.$store.commit('setAlertText', 'Successfully')
                                this.$store.commit('setVariantMessage', 'success')
                            }

                            await new Promise(resolve => setTimeout(resolve, 3000));
                            this.isTogglingNode = false
                        }

                        this.closePopper('popper' + instance + object.id)
                    }
                    }
                )

                return object;
            },
            closePopper(id){
                document.getElementById(id).click()
            }
        },
        watch:{
            selected: function(newValue, oldValue ){
                this.getSelectedAccountsData(null, newValue)
            },
            dateRange: function(){
                this.dateRangeFilter()
            },
            pagination: function(){
                if (!this.accounts.length) {
                    this.pagination = null
                }
            },
            searchValue: debounce(async function(){
                this.search()
            }, 400),
            selectedRowsAccounts: function(){
                if (this.selectedRowsAccounts.length) {
                    this.filterBar = true
                } else {
                    this.filterBar = false
                }
            }
        }

    }
</script>

<style scoped>
.dashboard-table-name{
    max-width: 175px;
    line-height: 15px;
}
.datapicker-class >>> .mx-input{
    height: 42px;
}
.dashboard__body-item-wrap >>> a:hover{
    color: unset;
}
.campaign-class{
    padding-left: 20px;
    line-height: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.campaign-checkbox{
    left: 15px;
}
.adset-class{
    padding-left: 40px;
    line-height: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.adset-checkbox{
    left: 30px;
}
.ads-class{
    padding-left: 60px;
    line-height: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.ads-checkbox{
    left: 45px;
}
.open-close-row{
    transform: scale(-1);
}
.dashboard__filters-dropdown{
    z-index: 11;
}
.dashboard-row{
    font-size: 30px;
    position: absolute;
    right: 0;
    left: 0;
}
.disabled {
  pointer-events: none;
  opacity: 0.3;
}
.filter-bar-place{
    height: 50px;
}
.table-header-filters{
    top: 0;
    z-index: 9;
    background-color: #F1F3F4;
    width: 100%;

}
.header-sticky{
    width: 100%;
    position: sticky;
    top: 0px;
    z-index: 9;
    background-color: #F1F3F4;
    padding-top: 10px;
}
.dashboard__table-head{
    position: sticky;
    top: 126px;
    z-index: 99;
    background-color: #F1F3F4;
}
.datapicker-class >>> .mx-datepicker-sidebar{
    float: right;
    width: 150px;
}
.datapicker-class >>> .mx-datepicker-content{
    margin-right: 150px;
    margin-left: unset;
}
.cpr-input{
    padding-left: 13px;
}
.cursor_wait{
    cursor: wait;
    user-select: none;
}
.cursor_wait * {
    pointer-events: none;
}
.cursor_pointer{
    cursor: pointer;
}

.input-budget{
    width: 100px;
    height: 30px;
}

.btn-budget{
    position: absolute;
    left: 10px;
    top: 2px;
    padding: 3px;
    z-index: 1;
}

.btn-budget::before,
.btn-budget::after {
 content: '';
  position: absolute;
  width: 100%;
  left: 0;
}
.btn-budget::before {
  background-color: #979797;
  height: 2px;
  bottom: 0;
  transform: scaleX(0);
  transition: transform .3s cubic-bezier(0.76, 0, 0.24, 1);
}
.btn-budget::after {
  content: attr(data-replace);
  height: 100%;
  top: 0;
  transition: transform .3s cubic-bezier(0.76, 0, 0.24, 1);
  color: #979797;
}

.btn-budget:hover::before {
  transform-origin: 0% 50%;
  transform: scaleX(1);
}
.btn-budget:hover::after {
  transform: translate3d(0, 0, 0);
}

.btn-budget span {
  display: inline-block;
  transition: transform .3s cubic-bezier(0.76, 0, 0.24, 1);
}

.edit-budget-icon{
    right: 1px;
    top: 2px;
}

.ok-budget{
    left: 3px;
    top: 8px;
    color: green;
}
.cancel-budget{
    right: 3px;
    top: 8px;
    color: red;
}
</style>
