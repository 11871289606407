<template>
    <div class="onboarding justify-content-center">
        <div class="onboarding__counter">
        </div>
            <div class="onboarding__content">
                <div class="row">
                    <div class="col-6">
                        <label for="email">Email</label>
                        <input class="form-control mt-3" type="email" disabled :value="user.email" id="email">
                    </div>
                    <div class="col-6">
                        <label for="name">Name</label>
                        <input class="form-control mt-3" type="text" disabled :value="user.name" id="name">
                    </div>
                </div>
                <div class="onboarding__info"></div>

                <form @submit.prevent="submitForm" class="mt-3">
                    <div class="row">
                        <div class="col-6">
                            <label for="email">Password</label>
                            <input required minlength="6" class="form-control mt-3" type="password" name="password" id="password">
                            <span v-show="this.error" class="error-pass">Passwords do not match</span>
                        </div>
                        <div class="col-6">
                            <label for="name">Password confirmation</label>
                            <input required minlength="6" class="form-control mt-3" type="password" name="password_confirm" id="password_confirm">
                            <span v-show="this.error" class="error-pass">Passwords do not match</span>
                        </div>
                    </div>

                    <button @click="" class="button button--small mt-3" type="submit">Set new password</button>
                </form>
        </div>
    </div>
</template>

<script>
import UrlService from '../services/UrlService'

    export default {
        data() {
            return {
                user: {
                    email: null,
                    name: null
                },
                error: false
            }
        },
        async mounted() {
            await this.getProfile()
        },
        methods:{
            async getProfile(){
                let response = await UrlService.getProfile()
                this.user = response.data.data
                this.$store.commit('setHasSyncedAccs', response.data.data.facebookAccounts)
            },
            async submitForm(submitEvent){
                this.error = false
                if (submitEvent.target.elements.password.value !== submitEvent.target.elements.password_confirm.value) {
                    this.error = true
                    return
                }
                let data = {
                    password: submitEvent.target.elements.password.value,
                    password_confirmation: submitEvent.target.elements.password_confirm.value
                }
                let res = await UrlService.saveProfile(data).catch(err => {
                    return err
                })

                if (res.status !== 200) {
                    this.$store.commit('setDismissCountDown', 5);
                    let errorText = '';
                    for (const error in res.response.data.errors) {
                        errorText += res.response.data.errors[error][0]

                    }
                    this.$store.commit('setAlertText', errorText)
                    this.$store.commit('setVariantMessage', 'danger')
                    return
                }

                this.$store.commit('setDismissCountDown', 5);
                this.$store.commit('setAlertText', 'Success!')
                this.$store.commit('setVariantMessage', 'success')
            }
        }

    }
</script>

<style scoped>
.error-pass{
    font-size: 10px;
    color:red
}
</style>
