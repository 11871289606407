<template>
    <div>
        <b-alert
            class="position-fixed error-message-bag"
            fade
            :show="this.$store.getters.getDismissCountDown"
            @dismiss-count-down="countDownChanged"
            :variant="this.$store.getters.getVariantMessage"
            v-html="this.$store.getters.getAlertText"
            >
        </b-alert>
    </div>
</template>

<script>
export default {
    data () {
        return {
            dismissSecs: 5,
            dismissCount: this.$store.getters.getDismissCountDown,
        }
    },
    methods:{
        countDownChanged(dismissCount) {
            this.dismissCount = dismissCount
            this.$store.commit('setDismissCountDown', dismissCount)
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
