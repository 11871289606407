<template>
    <fragment>
        <p style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
            <popper
                trigger="hover"
                :options="{
                placement: 'top',
                modifiers: { offset: { offset: '0,05px' } }
                }">
                <div class="popper">
                    {{ node.objective }}
                </div>
                <span slot="reference">
                    {{ node.objective }}
                </span>
            </popper>
        </p>
        <p>{{ new Intl.NumberFormat('en-US', { style: 'currency', currency: node.currency, maximumFractionDigits: 2 }).format(node.spend) }}</p>
        <p>{{ node.amount }}</p>
        <p :class="[node.class + '_cpr-table']">{{new Intl.NumberFormat('en-US', { style: 'currency', currency: node.currency, maximumFractionDigits: 2 }).format(node.cpr)}}</p>
        <p>{{ new Intl.NumberFormat('en-US', { style: 'decimal'}).format(node.impressions) }}</p>
        <p>{{ new Intl.NumberFormat('en-US', { maximumSignificantDigits: 3 }).format(node.frequency) }}</p>
        <p>{{ new Intl.NumberFormat('en-US', { style: 'decimal'}).format(node.reach) }}</p>
        <p>{{new Intl.NumberFormat('en-US', { style: 'currency', currency: node.currency, maximumFractionDigits: 2 }).format(node.cpm)}}</p>
        <p>{{ new Intl.NumberFormat('en-US', { style: 'decimal'}).format(node.ulc) }}</p>
        <p>{{new Intl.NumberFormat('en-US', { style: 'currency', currency: node.currency, maximumFractionDigits: 2 }).format(node.cpulc)}}</p>
        <p>{{ new Intl.NumberFormat('en-US', { style: 'decimal'}).format(node.clicks) }}</p>
        <p>{{new Intl.NumberFormat('en-US', { style: 'currency', currency: node.currency, maximumFractionDigits: 2 }).format(node.cpc)}}</p>
        <p>{{ new Intl.NumberFormat('en-US', { style: 'decimal'}).format(node.link_clicks) }}</p>
        <p>{{new Intl.NumberFormat('en-US', { style: 'currency', currency: node.currency, maximumFractionDigits: 2 }).format(node.cost_per_inline_link_click)}}</p>
        <p>{{ new Intl.NumberFormat('en-US', { style: 'percent', maximumFractionDigits: 2 }).format(node.ccvr) }}</p>
        <p>{{ new Intl.NumberFormat('en-US', { style: 'percent', maximumFractionDigits: 2 }).format(node.cvr) }}</p>
    </fragment>
</template>

<script>
    import { Fragment } from 'vue-fragment'
    import Popper from 'vue-popperjs';
    import 'vue-popperjs/dist/vue-popper.css';

    export default {
        components:{Fragment, Popper},
        props:{
            node:{
                required:true,
                type:Object
            }
        }
    }
</script>

<style scoped>

</style>
