<template>
    <div class="mt-5 col-10 h-100 dashboard__wrap_table w-50">
            <div class="col-12">
                    <label for="adaccount_id">Choose the account</label>
                    <v-select id="adaccount_id" v-model="selectedAdAccount" :options="selectOptions"></v-select>
            </div>
            <Transition name="next-block">
                <div v-if="selectedAdAccount" class="row mt-2">
                    <div class="col-6">
                        <div class="form-group">
                            <label for="name">Name of the campaign</label>
                            <input v-model="campaignName" type="text" class="form-control" id="name" placeholder="Enter name of the new campaign">
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label for="objective">Set objective of the campaign</label>
                            <v-select id="objective" v-model="selectedObjective" :options="selectObjectiveOptions"></v-select>
                        </div>
                    </div>
                </div>
            </Transition>
            <Transition v-if="campaignName" name="next-block">
                <div class="row mt-2">
                    <div class="col-6">
                        <div class="form-group">
                            <label for="status">Set status of the campaign</label>
                            <v-select id="status" v-model="selectedStatus" :options="selectStatusOptions"></v-select>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label for="spec_ctg">Special categories of the campaign</label>
                            <v-select id="spec_ctg" v-model="selectedSpecCtg" :options="selectSpecCtgOptions"></v-select>
                        </div>
                    </div>
                </div>
            </Transition>
            <button v-if="selectedAdAccount" type="button" class="mt-3 button button--small" @click="createCampaign">Create</button>

            <Transition>
                <div v-if="selectedAdAccount && campaigns.length" class="table-campaign mt-3">
                    <div class="dashboard__table row">
                        <div class="col-3">
                            <button type="button" class="dashboard__head-item">
                                <h6>Name</h6>
                                <img src="/img/svg/arrow-up-sort.svg" alt="sorting" />
                            </button>
                        </div>
                        <div class="col-3">
                            <button type="button" class="dashboard__head-item">
                                <h6>Objective</h6>
                                <img src="/img/svg/arrow-up-sort.svg" alt="sorting" />
                            </button>
                        </div>
                        <div class="col-3">
                            <button type="button" class="dashboard__head-item">
                                <h6>Status</h6>
                                <img src="/img/svg/arrow-up-sort.svg" alt="sorting" />
                            </button>
                        </div>
                        <div class="col-3"></div>
                    </div>
                    <div class="row">
                        <div v-for="campaign in campaigns" :key="campaign.id" class="col-12 align-items-center d-flex shadow-sm p-3 mb-1 bg-white rounded">
                            <div class="col-3">{{ campaign.name }}</div>
                            <div class="col-3">{{ campaign.objective }}</div>
                            <div class="col-3">{{ campaign.status }}</div>
                            <div class="col-3">
                                <button v-b-modal.adset-modal @click="createAdset(campaign.id)" type="button" class="button button--small">Create Adset</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Transition>

            <b-modal content-class="modal-adset" id="adset-modal" title="Create Adset" hide-footer>
                <adset v-if="adsetShow" :campaignId="campaignIdAdset"/>
                <b-button class="mt-3" block @click="$bvModal.hide('adset-modal')">Close</b-button>
            </b-modal>

    </div>
</template>

<script>
import UrlService from '../services/UrlService'
import Adset from './managment/Adset.vue'

    export default {
        components:{
            Adset
        },
        data() {
            return {
                accounts: [],
                campaignName: null,
                selectOptions: [],
                selectedAdAccount: null,
                selectObjectiveOptions: [],
                selectedObjective: 'LEAD_GENERATION',
                selectStatusOptions: [],
                selectedStatus: null,
                selectSpecCtgOptions: [],
                selectedSpecCtg: 'NONE',
                campaigns: [],
                adsetShow: false,
                campaignIdAdset: null,
            }
        },
        async mounted() {
            await this.getSelectedAccountsData();
            await this.getObjectiveOptions();
            await this.getStatusesOptions();
            await this.getSpecCategsOptions();
        },
        methods:{
            async getSelectedAccountsData(url = null, size = 100, filter = null){
                const data = await UrlService.getMyAdsAccounts();
                this.accounts = data.data.data

                this.accounts.map((account)=>{
                    this.selectOptions.push({label:account.name, code: account.id});
                })

            },
            async getObjectiveOptions(){
                const data = await UrlService.getObjectiveOptions();
                for (const key in data.data.data) {
                    this.selectObjectiveOptions.push(key)
                }
            },
            async getStatusesOptions(){
                const data = await UrlService.getStatusesOptions();
                for (const key in data.data.data) {
                    this.selectStatusOptions.push(key)
                }
            },
            async getSpecCategsOptions(){
                const data = await UrlService.getSpecCategsOptions();
                for (const key in data.data.data) {
                    this.selectSpecCtgOptions.push(key)
                }
            },
            async createCampaign(){
                let data = {
                    adaccount_id: this.selectedAdAccount.code,
                    name: this.campaignName,
                    objective: this.selectedObjective,
                    spec_ctg: this.selectedSpecCtg,
                    status: this.selectedStatus,
                }
                let response = await UrlService.createCampaign(data).catch(err => {
                    return err
                })

                if (response.status === 200) {
                    return this.campaigns = response.data.data
                }

                let message = '<ul>';
                for (const text of Object.values(response.response.data.errors)) {
                    message += '<li>'+text+'</li>'
                }
                message += '</ul>'

                this.$store.commit('setDismissCountDown', 5)
                this.$store.commit('setAlertText', message)
                this.$store.commit('setVariantMessage', 'danger')

            },
            async getCampaigns(){
                this.campaigns = []
                let response = await UrlService.getCampaignsList(this.selectedAdAccount.code).catch(err => {
                    return err
                })

                if (response.status === 200) {
                    return this.campaigns = response.data.data
                }

                this.$store.commit('setDismissCountDown', 5)
                this.$store.commit('setAlertText', response.response.data.message)
                this.$store.commit('setVariantMessage', 'danger')
            },
            createAdset(campaignId){
                this.adsetShow = true
                this.campaignIdAdset = campaignId
            }
        },
        watch:{
            selectedAdAccount: function(newValue, oldValue ){
                newValue ? this.getCampaigns() : null
            },
        }
    }
</script>

<style scoped>
/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.next-block-enter-active {
  transition: all 0.5s ease-in-out;
}

.next-block-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.next-block-enter-from,
.next-block-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
>>> .modal-backdrop{
    background-color: rgba(0,0,0,0.5)
}
</style>
