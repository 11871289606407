<template>
    <div class="onboarding">
        <div class="onboarding__counter">
            <button type="button" class="onboarding__count onboarding__count--active"><img src="/img/svg/success.svg" alt="success" /></button>
            <button type="button" @click="backClick" class="onboarding__count onboarding__count--active"><img src="/img/svg/success.svg" alt="success" ></button>
            <button type="button" class="onboarding__count onboarding__count--active">3</button>
            <button type="button" class="onboarding__count onboarding__count--active">4</button>
        </div>

        <div class="onboarding__content">
            <div class="onboarding__info">
            <h3 class="onboarding__title">Setup Your Traffic Light System</h3>
            <div class="text-center">
                <popper
                    trigger="hover"
                    :options="{
                    placement: 'top',
                    modifiers: { offset: { offset: '0,10px' } }
                    }">
                    <div class="popper">
                        <p>The Traffic Light System is used to highlight warnings on ad accounts, campaigns, adsets and ads to bring them to your attention.</p>
                        <p>Follow the instructions below to complete this step.</p>
                        <p>First set your Global Cost Per Result below.  Next, set up your Red warning threshold for your cost per result.</p>
                        <p>If you would like to override your cost per result on specific ad accounts you can do that by searching</p>
                        <p>for the account and updating the Cost Per Result column on your selected ad account on the chart below.</p>
                        <p>Once you are finished click Continue.</p>
                    </div>
                    <button slot="reference" style="width: 47px;">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) -->
                        <path d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"/></svg>
                    </button>
                </popper>
            </div>
            <div></div>
            <div class="d-flex justify-content-between">
                <div><p class="onboarding__section-title">Global Cost Per Result</p></div>
                <div class="d-flex"><p class="onboarding__section-title">Red %
                    <popper
                        trigger="hover"
                        :options="{
                        placement: 'top',
                        modifiers: { offset: { offset: '0,10px' } }
                        }">
                        <div class="popper">
                            Choose a percentage of which you would like to trigger a Red alert on your ad account.  e.g. <br>
                            If your goal for your cost per lead is $10 and you want a red alert warning at $14, enter 140%. <br>
                            Anything above $10 but below $14 will be marked at Orange alert.
                        </div>
                        <button slot="reference" style="width: 27px;">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) -->
                            <path d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"/></svg>
                        </button>
                    </popper>
                    </p>
                </div>
            </div>
            <div class="onboarding__input-section">
                <div class="input-wrap">
                    <input type="text" v-model="cprforAllRows" @keypress="onlyNumber" placeholder="0.00" class="text-input cpr-global text-end" />
                </div>

                <div class="position-relative">
                    <input @change="changeGlobalCTR($event)" maxlength="3" @keypress="onlyNumber" v-model="high_cpr" min="0" max="100" name="cost_per_result_high" class="text-input cpr-global text-end" type="text" placeholder="%" />
                    <div v-show="redError" class="invalid-error">
                        The field is required.
                    </div>
                </div>
            </div>
            <h4 class="onboarding__section-title">Override account-level KPIs</h4>
            <div class="onboarding__filter-wrap">
                <div class="input-wrap input-wrap--search input-wrap--wide">
                    <input @keyup="search" v-model="searchValue" class="text-input text-input--search text-input--search-wide" type="text" placeholder="Search by accounts" />
                </div>
            </div>
            <div class="onboarding__table">
                <div class="onboarding__table-head">
                        <div>
                            <div class="onboarding__sorting" @click="sortedArray">A-Z</div>
                            <h6 class="onboarding__name-account">Ad account name</h6>
                        </div>
                        <div><h6>Cost per result</h6></div>

                </div>
                <ul class="onboarding__table-body">
                        <div v-if="accounts">
                            <div v-for="account in accounts" :key="account.id" class="d-flex mb-3">
                                <li class="onboarding__table-item">
                                    <div><h6>{{account.name}}</h6></div>
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text text-uppercase">{{ account.currency }}</span>
                                            </div>
                                            <loader class="input-loader" :isLoading="isLoading"></loader>
                                            <input :class="{'opacity-25':isLoading}" :disabled="isLoading" type="text" @keypress="onlyNumber" @change="changeLeadCost(account, $event)" placeholder="0.00" :value="account.lead_cost" class="form-control" aria-label="Amount (to the nearest dollar)">
                                        </div>

                                </li>
                            </div>
                        </div>
                    <div v-else class="d-flex justify-content-between align-items-center">
                        <div>
                            <li class="onboarding__table-item">
                                <div><h6>Please select an account in the previous step</h6></div>
                            </li>
                        </div>
                    </div>
                </ul>
            </div>
            </div>
        </div>
        <div class="onboarding__button-wrap">
            <a @click.prevent="syncSelectedAccounts" href="#" class="button button--large">
                Continue
            </a>
        </div>
    </div>
</template>

<script>
import UrlService from '../services/UrlService'
import debounce from 'lodash.debounce'
import Loader from '../Loader.vue'
import _ from 'lodash'
import Popper from 'vue-popperjs';
import 'vue-popperjs/dist/vue-popper.css';

    export default {
        components: {Loader, Popper},
        props: [
            'infourl',
            'ctrurl',
            'getglobctrurl',
            ],
        data() {
            return {
                accounts: null,
                searchValue: '',
                filterUrl: '',
                high_cpr: 0,
                secondStepUrl: UrlService.secondStepUrl(),
                finishStepUrl: UrlService.finishStepUrl(),
                cprforAllRows: '',
                isLoading: false,
                sort: true,
                redError: false,
            }
        },
        mounted() {
            this.getSelectedAccounts();
            this.getGlobalCTR();

        },
        methods:{
            async getSelectedAccounts(){
                const response = await UrlService.getOnboardingSelectedAccounts().catch(err => {
                        return err
                    })

                    if (response.status === 200) {
                    this.accounts = response.data.data

                        for (const [key, account] of Object.entries(this.accounts)){
                            if(account.settings.length){
                                for (const [skey, setting_value] of Object.entries(account.settings)) {
                                    account.lead_cost = parseFloat(setting_value.pivot.value).toFixed(2)
                                }

                            }
                        }
                    } else {
                        this.$store.commit('setDismissCountDown', 5)
                        this.$store.commit('setAlertText', response.response.data.message)
                        this.$store.commit('setVariantMessage', 'danger')
                    }
            },

            changeLeadCost(account, event){
                UrlService.changeLeadCost({
                    id: account.id,
                    lead_cost: event.target.value
                }).then(response => {

                }).catch(error => {
                    // console.log(error.toJSON());
                });
            },
            changeGlobalCTR(event){

                UrlService.changeGlobalCTR({
                   key:event.target.name,
                   value:event.target.value
                }).then(response => {

                }).catch(error => {
                    // console.log(error.toJSON());
                });
            },
            getGlobalCTR(){
                UrlService.getGlobalCTR().then(response => {
                    this.high_cpr = response.data.data.cost_per_result_high;
                }).catch(error => {
                    // console.log(error.toJSON());
                });
            },
            search(){
                if(this.searchValue.length > 1){
                    this.filterUrl = '?filter[id]=' + this.$store.getters.getSelectedAccounts + '&filter[name]=' + this.searchValue
                } else {
                    this.filterUrl = '?filter[id]=' + this.$store.getters.getSelectedAccounts
                }
                this.getSelectedAccounts();
            },
            onlyNumber ($event) {
                let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
                if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
                    $event.preventDefault();
                }
            },
            sortedArray() {
                this.sort = !this.sort
                this.accounts = _.orderBy(this.accounts, 'name', this.sort ? 'asc' : 'desc')
            },
            async syncSelectedAccounts(){
                if (!this.high_cpr) {
                    this.redError = true
                }else{
                    this.redError = false
                }

                if(!this.high_cpr) return

                window.location.href = this.finishStepUrl

            },
            async setCPRforAllRows(){
                this.isLoading = true
                let cost = this.cprforAllRows
                if (cost) {
                    let response = await UrlService.changeLeadCostMass({
                            ids: _.map(this.accounts, 'id'),
                            lead_cost: cost
                        })
                    this.getSelectedAccounts();
                }

                this.isLoading = false
            },
            backClick(){
                window.location.href=this.secondStepUrl
            }

        },
        computed: {
        },
        watch: {
            cprforAllRows: debounce(async function(){
                this.setCPRforAllRows()
            }, 500)
        },
    }
</script>
<style scoped>
.cpr-global{
    max-width: 120px;
}
.onboarding__table-body{
    min-height: 125px;
}
.onboarding{
    overflow: hidden;
    max-height:none
}
.input-loader{
    font-size: 12px;
    position: absolute;
    left: 50px;
}
.invalid-error{
    position: absolute;
    font-size: 12px;
    color: #dc3545;
}
</style>
