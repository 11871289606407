import router from "./router.js";
import store from './store'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import Fragment from 'vue-fragment'
import VueTabs from 'vue-nav-tabs/dist/vue-tabs'
import 'vue-nav-tabs/themes/vue-tabs.css'
import VueConfirmDialog from 'vue-confirm-dialog'

require('./bootstrap');

window.Vue = require('vue').default;

Vue.component('v-select', vSelect)

Vue.component('SecondStep', require('./components/integration/SecondStep').default);
Vue.component('ThirdStep', require('./components/integration/ThirdStep').default);
Vue.component('Index', require('./components/dashboard/Index').default);
Vue.component('CreateCampaign', require('./components/dashboard/CreateCampaign').default);
Vue.component('Message', require('./components/Message').default);
Vue.component('Profile', require('./components/user/Profile').default);


Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(Fragment.Plugin)
Vue.use(VueTabs)
Vue.use(VueConfirmDialog)


const app = new Vue({
    el: '#app',
    // router,
    store
});
