import Vue from "vue";
import Router from "vue-router";
import store from './store'
import FirstStep from './components/integration/FirstStep'
import SecondStep from './components/integration/SecondStep'
import ThirdStep from './components/integration/ThirdStep'
import Profile from './components/user/Profile'

Vue.use(Router);

const hasSyncedAccs = (to, from, next) => {
    if (store.getters.hasSyncedAccs) {
        next()
        return
      }
      next('/')
  }

const router = new Router({
    linkExactActiveClass: "active",
    // mode: 'history',
    routes: [
        {
            path: '/',
            name: 'second-step-extended',
            component: SecondStep
        },
        {
            path: '/profile',
            name: 'profile',
            component: Profile
        },
        // {
        //     path: '/accounts',
        //     name: 'second-step',
        //     component: SecondStep,
        //     beforeEnter: hasSyncedAccs
        // },
        // {
        //     path: '/accounts/selected',
        //     props: true,
        //     name: 'third-step',
        //     component: ThirdStep,
        //     beforeEnter: hasSyncedAccs
        // },
    ]
  })
// router.beforeEach((to, from, next) => {
//     if (store.getters.isAuthenticated) {
//         next()
//         return
//       }

//       next(process.env.MIX_APP_URL)
//   });

  export default router
