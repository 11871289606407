import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
    plugins: [createPersistedState({
        storage: window.sessionStorage,
    })],
    state:{
        selectedAccounts: null,
        hasSyncedAccs: false,
        authenticated: false,
        dismissCountDown: 0,
        alertText: '',
        variantMessage: '',
    },
    getters:{
      getSelectedAccounts: state => state.selectedAccounts,
      hasSyncedAccs: state => state.hasSyncedAccs,
      isAuthenticated: state => state.authenticated,
      getDismissCountDown: state => state.dismissCountDown,
      getAlertText: state => state.alertText,
      getVariantMessage: state => state.variantMessage,
    },
    mutations: {
      setSelectedAccounts(state, ids) {
        state.selectedAccounts = ids;
      },
      setHasSyncedAccs(state, value) {
        state.hasSyncedAccs = value;
      },
      setAuthenticated(state, value) {
        state.authenticated = value;
      },
      setDismissCountDown(state, value) {
        state.dismissCountDown = value;
      },
      setAlertText(state, value) {
        state.alertText = value;
      },
      setVariantMessage(state, value) {
        state.variantMessage = value;
      },
    },
    actions:{}
  })
