<template>
    <div class="onboarding">
        <div class="onboarding__counter">
            <button type="button" class="onboarding__count onboarding__count--active">1</button>
            <button type="button" class="onboarding__count">2</button>
            <button type="button" class="onboarding__count">3</button>
            <button type="button" class="onboarding__count">4</button>
        </div>
        <div class="onboarding__content">
            <div class="onboarding__info">
            <h3 class="onboarding__title">Welcome to our service</h3>
            <p class="onboarding__subtitle">Integrate ads with Facebook</p>
            </div>
            <div class="onboarding__button-wrap">
            <a :href="facebookAuthUrl" type="button" class="button button--large">
                <div class="icon-wrap">
                    <img src="/img/svg/facebook.svg" alt="facebook" />
                </div>
                Integrate
            </a>
            </div>
        </div>
    </div>
</template>

<script>
import UrlService from '../services/UrlService'
    export default {
        data() {
            return {
                hasSyncedAccs: this.$store.getters.hasSyncedAccs,
                facebookAuthUrl: UrlService.authSocialFacebookUrl()
            }
        },
        mounted() {
            if (this.hasSyncedAccs) {
                this.$router.push({ name: "second-step"})
            }
        },
        methods:{
        }

    }
</script>
