<template>
    <div class="row">
        <div class="col-12">
            <div class="form-group">
                <label for="name">Name of the campaign</label>
                <input v-model="adsetName" type="text" class="form-control" id="name" placeholder="Enter name of the new adset">
            </div>
        </div>
        <div class="col-12 row">
            <div class="col-6">
            <div class="form-group">
                <label for="dbudget">Daily budget</label>
                <input v-model="dailyBudget" type="text" class="form-control" id="dbudget">
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <label for="bidamount">Bid amount</label>
                    <input v-model="bidAmount" type="text" class="form-control" id="bidamount">
                </div>
            </div>
        </div>
        <div class="col-12 row">
            <div class="col-6">
            <div class="form-group">
                <label for="billingEvent">Billing event</label>
                <v-select id="billingEvent" v-model="selectedBillingEvent" :options="selectBillingEvents"></v-select>
                </div>
            </div>
            <!-- <div class="col-6">
                <div class="form-group">
                    <label for="billingEvent">Targetting</label>
                    <v-select id="billingEvent" v-model="selectedBillingEvent" :options="selectTargetting"></v-select>
                </div>
            </div> -->
        </div>
        <div class="col-12 row">
            <div class="col-6">
                <div class="form-group">
                    <label for="promotedObject">Promoted object</label>
                    <v-select id="promotedObject" v-model="selectedPromotedObject" :options="selectPromotedObjects"></v-select>
                </div>
            </div>
            <div v-if="selectedPromotedObject" class="col-6">
                <div class="form-group">
                    <label for="promotedObjectValue">Enter the {{ selectedPromotedObject.toLowerCase().trim() }} value</label>
                    <input class="form-control" type="text" id="promotedObjectValue" v-model="promotedObjectValue">
                </div>
            </div>
        </div>

        <button type="button" class="mt-3 button button--small" @click="createAdset">Save adset</button>



    </div>
</template>

<script>
import UrlService from '../../services/UrlService'
    export default {
        props:{
            campaignId:{
                required: true,
                type: Number
            }
        },
        data(){
            return{
                adsetName: null,
                dailyBudget: null,
                bidAmount: null,
                selectedBillingEvent: null,
                selectedPromotedObject: null,
                selectBillingEvents: [],
                selectTargetting: [],
                selectPromotedObjects: [],
                promotedObjectValue: null,
                dismissCountDown: 0,
                alertText: '',
                variantMessage: '',
            }
        },
       async mounted() {
            this.getPromotedObjects()
            this.getBillingEvents()
        },
        methods:{
            async createAdset(){
                let data = {
                    campaign_id: this.campaignId,
                    name: this.adsetName,
                    daily_budget: parseInt(this.dailyBudget),
                    bid_amount: parseInt(this.bidAmount),
                    billing_event: this.selectedBillingEvent,
                    promoted_object: this.selectedPromotedObject,
                    promoted_object_value: this.promotedObjectValue,
                }

                if (
                    !this.adsetName ||
                    !this.dailyBudget ||
                    !this.bidAmount ||
                    !this.selectedBillingEvent ||
                    !this.selectedPromotedObject ||
                    !this.promotedObjectValue
                    ) {
                    // return alert('All fields are required!')
                }

                let response = await UrlService.createAdset(data).catch(err => {
                    return err
                })

                if (response.status === 200) {
                    return this.adsets = response.data.data
                }

                let message = '<ul>'

                if (response.response.data.errors) {
                    for (let text of Object.values(response.response.data.errors)) {
                        message += '<li>'+text+'</li>'
                    }
                }else{
                    message += '<li>'+response.response.data.data.message+'</li>'
                    message += '<li>'+response.response.data.data.error_user_title+'</li>'
                    message += '<li>'+response.response.data.data.error_user_msg+'</li>'
                }

                message += '</ul>'

                this.$store.commit('setDismissCountDown', 15)
                this.$store.commit('setAlertText',  message)
                this.$store.commit('setVariantMessage', 'danger')

            },
            async getPromotedObjects(){
                let response = await UrlService.getPromotedObjectOptions()
                for (const key in response.data.data) {
                    this.selectPromotedObjects.push(key)
                }
            },
            async getBillingEvents(){
                let response = await UrlService.getBillingEventsOptions()
                for (const key in response.data.data) {
                    this.selectBillingEvents.push(key)
                }
            }
        }
    }
</script>

<style scoped>

</style>
